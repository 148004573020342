<script setup>
import { computed, onMounted } from 'vue';
import { getDataObjectById } from 'o365-dataobject';
import { modalInitialMatrix, modalFinalMatrix } from 'sja.vue.libraries.modules.utilities.js';
import MobileActionsCard from 'sja.vue.libraries.mob.MobileActionsCard.vue';
import { getOrCreateProcedure } from 'o365-modules';

const props = defineProps({
    itemID: Number,
    hazardID: Number,
    sjaID: Number,
    readOnly: Boolean,
    participants: Boolean
});

let dsHazards = getDataObjectById(`dsTasksHazards_${props.sjaID}`)

const hazardData = computed(() => {
    return dsHazards.data.filter(item => item.ID == props.hazardID);
});

function openModal(hazard, modaltype) {
    const dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`);
    const dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`);
    dsHazardsModalInitial.recordSource.whereClause = `ID = ${hazard.ID}`
    dsHazardsModalFinal.recordSource.whereClause = `ID = ${hazard.ID}`
    if (modaltype === "Initial") {
        dsHazardsModalInitial.load().then(() => modalInitialMatrix.value.show())
    }
    if (modaltype === "Final") {
        dsHazardsModalFinal.load().then(() => modalFinalMatrix.value.show())
    }
}

const procCreateActionRow = getOrCreateProcedure({ id: "procCreateActionRow", procedureName: "astp_SJA_CreateActionRow" });

async function newRow(row) {
    let dsTasksHazardsActions = null;
    let dsTasksHazardsActionsResp = null;
    try {
        await procCreateActionRow.execute({ ID: row.ID });
        dsTasksHazardsActions = getDataObjectById(`dsTasksHazardsActions_${row.SJA_ID}`);
        dsTasksHazardsActionsResp = getDataObjectById(`dsTasksHazardsActionsResp_${row.SJA_ID}`);
        await dsTasksHazardsActions.load();
        await dsTasksHazardsActionsResp.load();
        const scrollElement = document.querySelector('.flex-grow-1.scrollable.d-flex.flex-column');
        scrollElement.scrollTo({
            top: scrollElement.scrollHeight,
            behavior: 'smooth'
        });
    } catch (error) {
        console.error("Error loading data:", error);
    }
}

onMounted(() => {
    if (!props.readOnly) {
        checkActions();
    }
})

function checkActions() {
    let allActionTabs = document.querySelectorAll(`.action-tab-${props.hazardID}`);
    if (allActionTabs.length > 0) {
        allActionTabs.forEach((tab) => {
            tab.innerHTML = `<div class="d-flex align-items-center"><span> ` + $t('New Actions') + `</span><i type="button" class="new-action-button-${props.hazardID} ms-2 bi bi-plus-circle fs-6"></i></div>`;
        })
        const icon = document.querySelector(`.new-action-button-${props.hazardID}`);
        icon.addEventListener("click", function () {
            newRow(hazardData.value[0]);
        });
        icon.addEventListener("mouseover", function () {
            const title = $t('Add a new action.');
            icon.setAttribute('title', title);
        });
    }
}

</script>

<template>
    <div>
        <div v-for="(hazard, index) in hazardData" class="mt-2">
                <b class="w-100 d-flex bg-white">
                    <span>{{ hazard.Name }}</span>
                </b>
                <div>
                    <div class="d-flex flex-row p-1 mb-3" style="margin-right: 0.7rem;">
                        <div class="align-middle ms-auto me-3">
                            <div class="d-flex position-absolute align-items-center">
                                <span class="me-1">{{ $t('I') }}</span>
                                <i :title="$t('Edit the initial risk assessment.') + '\n' + $t('Probability') + ': ' + (hazard.ProbabilityNameAndDesc ? hazard.ProbabilityNameAndDesc : ' ') + '\n' + $t('Consequence') + ': ' + (hazard.ConsequenceNameAndDesc ? hazard.ConsequenceNameAndDesc : ' ')"
                                    type="button"
                                    :style="{ 'color': hazard.Color, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                    class="fs-3 bi bi-square-fill"
                                    @click="!props.readOnly ? openModal(hazard, 'Initial') : ''"></i>
                            </div>
                        </div>
                    </div>

                    <div>
                        <span>{{ $t('Hazard Name') }}:</span>
                        <OContentEditable class="w-100 h-100 p-1 text-break" :class="!props.readOnly ? 'form-control' : ''"
                            :isEditable="!props.readOnly" v-model="hazard.Name" @blur="dsHazards.save()" />
                    </div>

                    <div>
                        <span>{{ $t('Consequences') }}</span>
                        <OContentEditable class="w-100 h-100 p-1 text-break" :class="!props.readOnly ? 'form-control' : ''"
                            :isEditable="!props.readOnly" v-model="hazard.Consequences" @blur="dsHazards.save()" />
                    </div>

                    <div class="d-flex flex-row p-1" style="margin-right: 1rem;">
                        <div class="align-middle ms-auto me-3">
                            <div class="d-flex position-absolute align-items-center" style="margin-top: 7px;">
                                <span class="me-1">{{ $t('F') }}</span>
                                <i :title="$t('Edit the final risk assessment.') + '\n' + $t('Probability') + ': ' + (hazard.FinalProbabilityNameAndDesc ? hazard.FinalProbabilityNameAndDesc : ' ') + '\n' + $t('Consequence') + ': ' + (hazard.FinalConsequenceNameAndDesc ? hazard.FinalConsequenceNameAndDesc : ' ')"
                                    type="button"
                                    :style="{ 'color': hazard.FinalColor, 'cursor': props.readOnly ? 'default' : 'pointer' }"
                                    class="fs-3 bi bi-square-fill"
                                    @click="!props.readOnly ? openModal(hazard, 'Final') : ''"></i>
                            </div>
                        </div>
                    </div>

                    <div class="mb-3">
                        {{ $t('New and existing actions') }}
                    </div>
                    <div>
                        <div class="position-relative ">
                            <OTabs>
                                <OTab :title="$t('New Actions')" :linkClass='"action-tab-" + hazard.ID' class="mb-2"
                                    :active="hazard.ExistingActions == null">
                                    <MobileActionsCard :hazardRow="hazard" :sjaID="props.sjaID"
                                        :itemID="props.itemID" :readOnly="props.readOnly"
                                        :participants="props.participants" />
                                </OTab>
                                <OTab :title="$t('Existing Actions')" :active="hazard.ExistingActions != null">
                                    <OContentEditable class="w-100 h-100 mb-2 p-1 text-break action-card"
                                        style="min-height:109.6px"
                                        :class="{ 'inner-table-editable': !props.readOnly, 'inner-table-not-editable': props.readOnly }"
                                        :isEditable="!props.readOnly" v-model="hazard.ExistingActions"
                                        @blur="dsHazards.save()" />
                                </OTab>
                            </OTabs>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</template>

<style scoped>
.inner-table-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-not-editable {
    border: 1.5px solid var(--o365-grid-border) !important;
    border-radius: var(--bs-border-radius) !important;
}

.inner-table-editable:focus,
.inner-table-editable:hover {
    outline: 0;
    border: 1.5px solid rgba(70, 130, 180, .25) !important;
    box-shadow: 0px 0px 0px 2px rgba(70, 130, 180, .25) !important;
}
</style>